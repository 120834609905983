import * as React from "react"
import { Link } from "gatsby"
import "../styles/404.scss"

// markup
const NotFoundPage = () => {
  return (
    <main>
      <div className="notFound">
        <h1>404</h1>
        <p>
          ページが見つかりません <br />
          ごめんなサイホーン
        </p>
        <Link to="/" className="backToHome">トップへ戻る</Link>

      </div>
    </main>
  )
}

export default NotFoundPage
